<template>
  <div
    class="flex h-auto min-h-[400px] cursor-pointer flex-col rounded-2xl border-[1px]"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
  >
    <div class="flex flex-col xl:h-full">
      <div>
        <img
          :src="blogData.thumbnailBlogImageLink"
          alt="blogImage"
          class="pointer-events-none h-[170px] w-full select-none rounded-t-2xl object-cover xl:h-[240px]"
        />
      </div>
      <div class="flex flex-grow flex-col items-start px-4 pt-6 font-semibold md:px-6 lg:pt-8">
        <div class="inline-block pb-6 xl:pb-8">
          <div
            class="inline-flex h-auto items-center justify-center rounded-3xl bg-dnd_light_purple px-4 py-2"
          >
            <div class="font-manrope text-xs text-black xl:text-base">
              Рубрика «{{ blogData.categoryName }}»
            </div>
          </div>
        </div>
        <div class="h-[110px] flex-grow overflow-hidden lg:h-[168px]">
          <div
            class="h-10 text-left text-base leading-5 text-black lg:h-16 xl:text-2xl xl:font-bold"
          >
            {{ blogData.title }}
          </div>
          <div class="overflow-hidden pt-3 text-xs text-dnd_dark_grey xl:pt-4">
            <p
              class="truncate-text text-left font-manrope text-sm xl:text-base"
              v-html="sanitizedTruncatedBody"
            />
          </div>
        </div>
        <div class="mt-auto flex w-full flex-row justify-between py-6 lg:pt-4">
          <p class="font-manrope text-xs text-dnd_dark_grey lg:text-sm">{{ formattedDate }}</p>
          <p
            class="cursor-pointer font-manrope text-xs lg:text-sm"
            @click="goToBlog"
          >
            Подробнее
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DOMPurify from 'isomorphic-dompurify';
import dayjs from 'dayjs';

const props = defineProps({
  blogData: {
    type: Object,
    required: true,
  },
});

const router = useRouter();
const blogStore = useBlogStore();

const formattedDate = computed(() => {
  return dayjs(props.blogData.updatedAt).format('DD.MM.YYYY');
});

const categoryName = computed(() => {
  const category = blogStore.blogCategories.find((categ) => categ.id === props.blogData.categoryId);
  return category ? category.urlName : '';
});

const sanitizedBody = computed(() => {
  if (!props.blogData.body) return;
  const sanitizedHtml = DOMPurify.sanitize(props.blogData.body);
  return removeH1Tags(sanitizedHtml);
});

const maxLength = 200;
let isMouseDown = false;

const sanitizedTruncatedBody = computed(() => {
  if (import.meta.client) {
    const div = document.createElement('div');
    div.innerHTML = sanitizedBody.value;
    const text = div.textContent || div.innerText || '';
    return text.slice(0, maxLength) + '...';
  }
  return '';
});

const goToBlog = () => {
  router.push({
    path: `/blog/${categoryName.value}/${props.blogData.name}`,
  });
};

const removeH1Tags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const h1Tags = doc.querySelectorAll('h1');
  h1Tags.forEach((tag) => tag.remove());
  return doc.body.innerHTML;
};

const onMouseDown = () => {
  isMouseDown = true;
};

const onMouseUp = () => {
  if (isMouseDown) {
    goToBlog();
    isMouseDown = false;
  }
};

const onMouseMove = () => {
  if (isMouseDown) {
    isMouseDown = false;
  }
};
</script>

<style scoped>
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
